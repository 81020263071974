import axios from 'axios'
// import { Message } from 'element-ui';

const service = axios.create({
    baseURL: '',
    // timeout: 30000,// 超时时间 单位是ms，这里设置了30s的超时时间
})
// 请求拦截
service.interceptors.request.use(config => {
    // console.log('请求拦截')
    if (config.params) {
      config.params._t = Date.now()
    }
    if (!config.headers.token) {
        config.headers.token = window.sessionStorage.getItem('token', '')
    }
    return config
},error => {
    return Promise.reject(error)
})

// 响应拦截
service.interceptors.response.use(response => {
    const res = response.data
    // console.log('响应拦截')
    // console.log(JSON.parse(JSON.stringify(res)))
    if (res.code == 200||res.type=='success') {
        return res
    }else{
        // Message({
        //     message: res.msg,
        //     type: 'error',
        //     duration: 2000
        // })
        console.log(res)
        return Promise.reject(res || '错误')
    }
},error => {
    // Message({
    //     message: error.msg,
    //     type: 'error',
    //     duration: 2000
    // })
    return Promise.reject(error)
})
export const request =opt=>{
    return new Promise((resolve,reject) =>{
        service.request(opt).then(res=>{
            resolve(res)
        }).catch(err=>{
            reject(err)
        })
    });
}
