import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({//创建路由对象
    routes: [{
      path: '/',
      redirect: '/login'
    },{
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login')
    },{
      path: '/index',
      name: 'Index',
      redirect: '/cygk',
      component: () => import('@/views/index'),
      children:[{//产业概况
        path: '/cygk',
        name: 'Cygk',
        component: () => import('@/views/cygk')
      },{//产品分布
        path: '/cyfb',
        name: 'Cyfb',
        component: () => import('@/views/cyfb')
      },{//市场价格
        path: '/scjg',
        name: 'Scjg',
        component: () => import('@/views/scjg')
      },{//智慧生产
        path: '/zhsc',
        name: 'Zhsc',
        component: () => import('@/views/zhsc')
      },{//技术规程
        path: '/jsgc',
        name: 'Jsgc',
        component: () => import('@/views/jsgc')
      },{//专家服务
        path: '/zjfw',
        name: 'Zjfw',
        component: () => import('@/views/zjfw')
      },{//产业服务
        path: '/cyfw',
        name: 'Cyfw',
        component: () => import('@/views/cyfw')
      },{//产销对接
        path: '/cxdj',
        name: 'Cxdj',
        component: () => import('@/views/cxdj')
      },{//精品建设
        path: '/jpjs',
        name: 'Jpjs',
        component: () => import('@/views/jpjs')
      }]
    }]
})
  