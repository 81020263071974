<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
  #app {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #021241;
  }
</style>
