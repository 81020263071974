import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router' //导入路由
import store from './store'
import '@/assets/css/index.scss'

Vue.use(ElementUI);

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

import './utils/axios'

Vue.config.productionTip = false
Vue.prototype.$bus = new Vue();
new Vue({
    router, //挂载路由
    store,
    render: h => h(App),
}).$mount('#app')