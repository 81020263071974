import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // bgImg:'bg',//背景图片、
    },
    mutations: {
        // getBgImg(state, val) {//获取背景图片
        //     state.bgImg = val
        // }
    },
    actions: {
    },
    modules: {
    }
})